export default {
  NOVO_ESTABELECIMENTO: "Novo estabelecimento",
  CPF_CNPJ: "CPF ou CNPJ",
  RAZAO_SOCIAL: "Razão social",
  NOME_FANTASIA: "Nome fantasia",
  ANEXO_ARQUIVO: "Logo do cliente",
  EMAIL: "Email",
  MCC: "MCC",
  RAV: "Repassar custos ao pagador (RAV)",
  MULTIPLOS: "Exibir múltiplos cartões",
  BLOQUEARPARCELAS: "Bloquear alteração de parcelas",
  EXIBIRENDERECO: "Exibir endereço no checkout",
  UTILIZATEF: "Habilitar TEF",
  ENVIOUNICOWEBHOOK: "Envio Único Webhook Liquidação",
  REENVIOWEBHOOKLIQUIDACAO: "Reenviar status com erros",
  PAGAMENTOINTERNACIONAL: "Permite pagamento sem CPF",
  CANCELAMENTO: "Permite cancelamentos",
  ENVIARSTATUSEMPROGRESSOWEBHOOK: "Enviar status de criada GSP",
  PONTO_VENDA: "Ponto de venda",
  CLIENT_ID: "Client ID",
  SECRET_KEY: "Secret key",
  PARCEIRO_COMERCIAL: "Parceiro comercial",
  CEP: "CEP",
  ENDERECO: "Endereço",
  NUMERO: "Número",
  COMPLEMENTO: "Complemento",
  BAIRRO: "Bairro",
  CIDADE: "Cidade",
  ESTADO: "Estado",
  PAIS: "País",
  GERAR_TOKEN: "Gerar Token",
  NOVO_TOKEN: "Novo Token",
  NOVO_TOKEN_GERADO: "Seu novo Token foi gerado com sucesso!",
  SOFT_DESCRIPTOR: 'SoftDescriptor',
  SOFT_DESCRIPTOR_DESCRICAO: "É a descrição do serviço, mensagem ou nome do site que aparecerá na transação da fatura do portador do cartão de crédito.",
  COPIAR: "Copiar e Editar",
  ADQUIRENTE: "Adquirente",

  NOVO_CONTATO: "Novo Contato",
  NOVA_CONTA_BANCARIA: "Nova Conta Bancária",
  NOVO_CONVENIO: "Novo Convênio",
  NOVO_CONTA: "Nova conta",
  NOVO_USUARIO: "Adicionar usuário",
  NOVO_APPCLIENT: "Adicionar App Client",

  TIPO_CONTATO: "Tipo de Contato",

  DATA_INCLUSAO: "Data de inclusão",
  STATUS: "Status",
  LIBERAR_ANTECIPACAO: "Liberar antecipação de recorrência",
  LIMITE_DISPONIVEL: "Limite de crédito disponível",
  DATA_ANTECIPACACAO: "Data de início da antecipação",
  FATURAMENTO_MENSAL: "Faturamento mensal",
  PERCENTUAL_ANTECIPACADO: "Percentual de antecipação",
  LIMITE_MAX: "Limite máximo inicial",
  VALIDADE_INICIAL: "Validade do limite inicial (em dias)",
  TAXA_CREDITO_VISTA: "Taxa de crédito à vista",
  LIMITE_INADIMPLECIA: "Limite máximo de inadimplência",

  INATIVAR_SUCESSO: "Estabelecimento(s) inativado(s) com sucesso.",
  ATIVAR_SUCESSO: "Estabelecimento(s) ativado(s) com sucesso.",
  TITULO_INATIVAR: "Inativar estabelecimento(s)",
  TITULO_ATIVAR: "Ativar estabelecimento(s)",
  CONFIRMACAO_INATIVAR: "Deseja inativar estabelecimento(s) selecionado(s)?",
  CONFIRMACAO_ATIVAR: "Deseja ativar estabelecimento(s) selecionado(s)?",
  BOTAO_INATIVAR: "Inativar",
  BOTAO_ATIVAR: "Ativar",
  TITULO_SALVAR: "Salvar dados do estabelecimento",
  MENSAGEM_SUCESSO: "Estabelecimento salvo com sucesso",
  CONFIRMAR_GERAR_NOVO_TOKEN_TITULO:
    "Tem certeza que gostaria de gerar um novo token?",
  CONFIRMAR_GERAR_NOVO_TOKEN_MENSAGEM:
    "Esta ação irá invalidar seu antigo token.",

  TAXA_DEBITO: "Taxa débito",
  TAXA_CREDITO: "Taxa crédito",
  TAXA_ENTRE2E6: "Taxa entre 2x e 6x",
  TAXA_ENTRE7E12: "Taxa entre 7x e 12x",
  TAXA_ENTRE13E21: "Taxa entre 13x e 21x",
  TAXA_ANTECIPACAO_MENSAL: "Taxa de antecipação mensal",

  TITULOS: {
    INTEGRACAO: "Integração",
    TAXAS: "Taxas"
  },

  VALIDACAO: {
    MENSAGEM_CLIENT_ID: "É necessário informar o Client ID ao informar o Secret key",
    MENSAGEM_SECRET_KEY: "É necessário informar o Secret key ao informar o Client ID"
  },

  CONTATO: {
    TITULO_MODAL_NOVO: "Novo contato",
    TITULO_MODAL_EDITAR: "Editar contato",
    NOME: "Nome",
    EMAIL: "E-mail",
    CELULAR: "Telefone/Celular",
    TIPO: "Tipo",
    ADICIONADO: "Contato salvo com sucesso!",
  },

  CONVENIO: {
    TITULO_MODAL_EDITAR: "Editar convênio",
    NOME: "Nome",
    TAXA: "Taxa",
    FAVORECIDO: "Favorecido",
    CPF_CNPJ: "CPF / CNPJ",
    BANCO: "Banco",
    TIPO_CONTA: "Tipo de Conta",
    AGENCIA: "Agência",
    CONTA: "Conta",
    DV: "DV",
    DV_AGENCIA: "DV Ag.",
    WEBHOOK_URL: "Webhook URL",
    WEBHOOK_USUARIO: "Webhook Usuário",
    WEBHOOK_SENHA: "Webhook Senha",
    INATIVAR_SUCESSO: "Convênio(s) inativado(s) com sucesso.",
    ATIVAR_SUCESSO: "Convênio(s) ativado(s) com sucesso.",
    TITULO_INATIVAR: "Inativar convênio(s).",
    TITULO_ATIVAR: "Ativar convênio(s).",
    BOTAO_INATIVAR: "Inativar",
    BOTAO_ATIVAR: "Ativar ",
    CONFIRMACAO_INATIVAR: "Deseja inativar convênio(s) selecionado(s)?",
    CONFIRMACAO_ATIVAR: "Deseja ativar convênio(s) selecionada(s)?",
    CONVENIO: "Convênio",
    TOKEN: "Token",
  },

  CONTA_BANCARIA: {
    TITULO_MODAL_NOVO: "Novo conta bancária",
    TITULO_MODAL_EDITAR: "Editar conta bancária",
    CPF_CNPJ: "CPF ou CNPJ",
    FAVORECIDO: "Favorecido",
    BANCO: "Banco",
    TIPO_CONTA: "Tipo de Conta",
    AGENCIA: "Agência",
    CONTA: "Conta",
    TOKEN: "Token",

    INATIVAR_SUCESSO: "Conta(s) bancária(s) inativada(s) com sucesso.",
    ATIVAR_SUCESSO: "Conta(s) bancária(s) ativado(s) com sucesso.",
    TITULO_INATIVAR: "Inativar conta(s) bancária(s)",
    TITULO_ATIVAR: "Ativar conta(s) bancária(s)",
    CONFIRMACAO_INATIVAR:
      "Deseja inativar conta(s) bancária(s) selecionada(s)?",
    CONFIRMACAO_ATIVAR: "Deseja ativar conta(s) bancária(s) selecionada(s)?",
    BOTAO_INATIVAR: "Inativar",
    BOTAO_ATIVAR: "Ativar",
    ADICIONADO: "Conta bancária salva com sucesso!",
  },
  USUARIOS: {
    FILTRO: "Filtrar pelo nome",
    ADICIONADO: "Usuário(s) adicionado(s) com sucesso!",
  },

  APPCLIENT: {
    TITULO_MODAL_NOVO: "Novo App Client",
    TITULO_MODAL_EDITAR: "Editar App Client",
    CLIENTID: "Client ID",
    SECRETKEY: "Secret key",
    PLATAFORMA: "Plataforma",
    ADQUIRENTE: "Adquirente",
    ATIVO: "Ativo",
    ADICIONADO: "App Client salvo com sucesso!",
    TITULO_LIGAR: "Ligar App Client",
    CONFIRMACAO_LIGAR: "Deseja ligar App Client selecionado?",
    BOTAO_LIGAR: "Ligar",
    LIGAR_SUCESSO: "App Client Ligado com sucesso.",
    TITULO_DESLIGAR: "Desligar App Client",
    CONFIRMACAO_DESLIGAR:
      "Deseja desligar App Client selecionado?",
    BOTAO_DESLIGAR: "Desligar",
    DESLIGAR_SUCESSO: "App Client desligado com sucesso."
  },
};
